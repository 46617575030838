import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AlertsService } from "./alerts.service";
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { DataService } from "./data.service";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private userId: number;
  private isAdminCached: boolean | null = null;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private msalService: MsalService,
    private alerts: AlertsService,
    private msalBroadcastService: MsalBroadcastService,
    private dataService: DataService,
    private router: Router) {
  }

  loginRedirect() {
    const request: RedirectRequest = {
      scopes: ['user.read', 'email'],
    };
    this.msalService.loginRedirect(request);
  }

  isUserAuthenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  public logout(popup?: boolean) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: "/signin"
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }

  public getUserId(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.dataService.get<any>(`${environment.apiUrl}Employee/GetUserIdByEmail`).then(
        res => {
          this.userId = res;
          resolve(this.userId);
        },
        err => {
          let errorMessage = 'An error occurred while retrieving user ID. Please try again later.';
          if (err.status === 404) {
            errorMessage = 'User ID not found.';
          } else if (err.status === 500) {
            errorMessage = 'Internal server error occurred.';
          }
          this.alerts.showError(errorMessage);
          reject(errorMessage);
        }
      );
    });
  }

  setupAccountStorageEvents() {
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        }
      });
  }

  setupInProgressListener() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      })
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }

    if (this.isUserAuthenticated()) {
      this.router.navigateByUrl('/companies');
    }
  }

  getEmail(): string | null {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount) {
      return null;
    }
    const email = activeAccount.idTokenClaims?.['email'] || 
                  activeAccount.idTokenClaims?.['preferred_username'];
  
    return typeof email === 'string' ? email : null;
  }
  
  getAccessToken(): Promise<string | null> {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount) {
      return Promise.resolve(null);
    }
    const request = {
      scopes: [process.env.API_ACCESS],
      account: activeAccount,
    };

    return this.msalService.acquireTokenSilent(request).toPromise()
      .then((response) => response?.accessToken || null)
      .catch(() => null);
  }

  getRolesFromAccessToken(): Promise<string[]> {
    return this.getAccessToken()
      .then((accessToken) => {
        if (!accessToken) return [];

        const decodedPayload = this.decodeTokenPayload(accessToken);
        return decodedPayload?.roles || []; 
    });
  }

  isAdmin(): Promise<boolean> {
    if (this.isAdminCached !== null) {
      return Promise.resolve(this.isAdminCached);
    }
    
    return this.getRolesFromAccessToken()
      .then((roles) => {
        this.isAdminCached = roles.includes('Administrator');
        return this.isAdminCached;
      });
  }

  private decodeTokenPayload(token: string): any {
    try {
      const base64Url = token.split('.')[1]; 
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = atob(base64);

      return JSON.parse(jsonPayload);
    } 
    catch {
      return null;
    }
  }
}