import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private toast: ToastrService) { }
  
  showError(err: any) {
    if (typeof err === 'undefined' || err === null || err === 0 || 
        (typeof err === 'string' && err !== undefined && err !== null && err.trim() === '')) {
      this.toast.error('Unknown error');
    } else {
      this.toast.error(err);
    }
  }

  showSuccess(message: string = 'Success') {
    this.toast.success(message);
  }
}

